import apiClient from 'src/config/api';
import { CourseUser, RoleType } from 'src/interfaces/entities/course-user';

import { Params } from '../types';
import { CourseUserParams } from './types';

export const getUsersInCourseRequest = (params: CourseUserParams) =>
  apiClient.get<CourseUser[]>(`course/${params.courseId}/course-user${params.query}`);

export const getUsersWithoutGroupRequest = (params: CourseUserParams) =>
  apiClient.get<CourseUser[]>(`course/${params.courseId}/course-user/without-group${params.query}`);

export const disableByUserIdRequest = (params: CourseUserParams) =>
  apiClient.patch<CourseUser>(`course/${params.courseId}/course-user/${params.userId}`);

export const addCourseUsersRequest = (
  params: Params<{ user: string; role: RoleType; isActive: boolean }>,
) => apiClient.post<CourseUser>(`course/${params.id}/course-user/`, params.data);
