import * as React from 'react';
import { Alert, AlertTitle } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';

import { useAppDispatch, useAppSelector } from 'src/redux';
import { RootReducer } from 'src/redux/modules/types';
import { hideSnackbar } from 'src/redux/modules/ui/actions';

const CustomSnackbar = ({ ...props }) => {
  const { title, message, open, severity } = useAppSelector(
    (state: RootReducer) => state.ui.snackbar,
  );
  const dispatch = useAppDispatch();

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(hideSnackbar());
  };

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar
        {...props}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={open}
        onClose={handleClose}
        data-testid="snackbar-container"
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          sx={{
            ' &.MuiAlert-root > div:first-child': {
              alignItems: 'center',
              padding: '10px 10px',
              margin: '0px',
              color: `${severity}.main`,
            },
            ' &.MuiAlert-root > div:nth-child(2)': {
              alignItems: 'center',
              padding: '10px 10px',
              margin: '5px 0px',
            },
            ' &.MuiAlert-root > div:last-child': {
              alignItems: 'center',
              padding: '10px 10px',
              margin: '0px',
              color: 'gray',
            },
            ' &.MuiAlert-root': {
              backgroundColor: '#FAFAFA',
              boxShadow: '2px 2px 10px 1px rgba(0,0,0,0.3)',
              color: 'black',
              padding: '0px',
              borderRadius: '5px',
              '::before': {
                content: '""',
                display: 'block',
                width: '10px',
                borderRadius: '4px 0px 0px 5px',
                backgroundColor: `${severity}.main`,
              },
            },
          }}
        >
          <AlertTitle data-testid="snackbar-message">
            <strong>{title}</strong>
          </AlertTitle>
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default CustomSnackbar;
