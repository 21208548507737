import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import React from 'react';
import { Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import { isMobile } from 'src/utils/mobile';

import RenderToolbar from './Toolbar';

export interface PDFViewerProps {
  url: string;
}

const PDFViewer = ({ url }: PDFViewerProps) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => (isMobile() ? [] : [defaultTabs[0]]),
    renderToolbar: RenderToolbar,
  });
  return (
    <Viewer
      plugins={[defaultLayoutPluginInstance]}
      fileUrl={`${url}#view=FitH&toolbar=0&scrollbar=0`}
    />
  );
};

export default PDFViewer;
