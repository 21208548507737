import React from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { Button } from '@mui/material';

import { Text } from 'src/components/shared/ui';
import { confirmCancel } from 'src/constants/modal-content';
import useWindowDimensions from 'src/hooks/useWindowDimensions';
import { useAppDispatch } from 'src/redux';
import { openModal } from 'src/redux/modules/ui/actions';

import styles from './go-back-button.module.css';
import { GoBackButtonProps } from './types';

const GoBackButton = ({ route, showModal }: GoBackButtonProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isPhone } = useWindowDimensions();

  const routeNavigate = () => (route === '-1' ? navigate(-1) : navigate(route));

  return (
    <Button
      data-testid="go-back-button"
      className={styles.container}
      onClick={() => {
        showModal
          ? dispatch(
              openModal(
                confirmCancel({
                  handleConfirm: () => routeNavigate(),
                }),
              ),
            )
          : routeNavigate();
      }}
    >
      <ArrowBackRoundedIcon sx={{ color: 'subtitle.main', fontSize: isPhone ? '18px' : '24px' }} />
      <Text variant="h2" fontWeight="400" color="subtitle" fontSize={isPhone ? '14px' : '18px'}>
        Volver
      </Text>
    </Button>
  );
};

export default GoBackButton;
