import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Card } from '@mui/material';

import { Text } from 'src/components/shared/ui';
import { RoleType } from 'src/interfaces/entities/course-user';
import { useAppSelector } from 'src/redux';
import { RootReducer } from 'src/redux/modules/types';
import {
  convertRoleToRoute,
  getRoleLabel,
  setStateTextColor,
  wrapName,
} from 'src/utils/formatters';

import styles from './card.module.css';
import { CustomCardProps } from './types';

const setClassByRole = (roleType: RoleType) => {
  switch (roleType) {
    case 'ADMIN':
      return styles.cardContainerAdmin;
    case 'STUDENT':
      return styles.cardContainerStudent;
    case 'TUTOR':
    case 'AUXILIARY':
      return styles.cardContainerTutor;
    default:
      return '';
  }
};

const CustomCard = ({
  roleType,
  courseName,
  courseId,
  courseStatus,
}: CustomCardProps): JSX.Element => {
  const { authenticated } = useAppSelector((state: RootReducer) => state.auth);
  const status = setStateTextColor(courseStatus);
  return (
    <Card
      data-testid={`${courseName}-card-container`}
      raised={true}
      className={setClassByRole(roleType)}
    >
      <Box className={styles.cardBox} bgcolor={status?.color}>
        <Text color="white" fontSize="12px" fontWeight="600">
          {status?.text}
        </Text>
      </Box>
      <Box className={styles.cardBoxTitle}>
        <Text className={styles.cardTextTitle} variant="h1" color="primary" fontSize="20px">
          {wrapName(courseName, 65, 70)}
        </Text>
      </Box>
      <Box className={styles.cardBoxSubtitle}>
        <Text fontSize="16px" color="primary">
          {getRoleLabel(roleType, authenticated?.userType)}
        </Text>
      </Box>
      <Box className={styles.btnContainer}>
        <Link to={convertRoleToRoute(roleType, courseId)} className={styles.linkButton}>
          <Button
            data-testid={`${courseName}-view-button`}
            variant="contained"
            className={styles.buttonCard}
            color="primary"
          >
            <Text variant="body2" color="white" fontWeight="600">
              VER CURSO
            </Text>
          </Button>
        </Link>
      </Box>
    </Card>
  );
};

export default CustomCard;
