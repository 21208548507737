import React, { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LinkIcon from '@mui/icons-material/Link';
import {
  Alert,
  Box,
  Button,
  IconButton,
  Slide,
  SlideProps,
  Snackbar,
  Tooltip,
} from '@mui/material';

import { Text } from 'src/components/shared/ui';
import { GeneralDataType } from 'src/interfaces';
import { Course } from 'src/interfaces/entities/course';

import { ActionItemsProps } from '../../types';
import styles from './index.module.css';

const TransitionUp = (props: JSX.IntrinsicAttributes & SlideProps) => {
  return <Slide {...props} direction="up" />;
};

const ActionItems = <DataType extends GeneralDataType>({
  handleSubmit,
  onEditableSubmit,
  disabled,
  isDirty,
  disabledEditableRow,
  isLoading,
  saveEditableText,
  index,
  onRowEditableSubmit,
  reset,
  setDisabledEditableRow,
  customIconText,
  linkIcon,
  handleLinkIcon,
  editIcon,
  deleteIcon,
  duplicateIcon,
  handleCustomIcon,
  handleEdit,
  handleDelete,
  handleDuplicate,
  row,
  disableDeleteIcon,
}: ActionItemsProps<DataType>) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const courseIsActive = row as unknown as Course;

  const handleLinkClick = () => {
    handleLinkIcon(row._id);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <div className={styles.buttonsContainer}>
      {saveEditableText && (
        <Button
          onClick={() => {
            handleSubmit((data) => onEditableSubmit(data))();
          }}
          disabled={disabled || !isDirty || disabledEditableRow || isLoading}
        >
          <Text
            variant={disabled || !isDirty || disabledEditableRow ? 'disableText' : 'body2Underline'}
            color={disabled || !isDirty || disabledEditableRow ? 'info' : 'secondary'}
          >
            {saveEditableText}
          </Text>
        </Button>
      )}
      {onRowEditableSubmit && (
        <>
          <IconButton
            data-testid={`edit-button-${index}`}
            onClick={() => {
              !disabledEditableRow &&
                handleSubmit((data) => {
                  onRowEditableSubmit(data);
                  reset(data);
                })();
              setDisabledEditableRow(!disabledEditableRow);
            }}
            disabled={(!isDirty && !disabledEditableRow) || isLoading}
          >
            <Box>
              {disabledEditableRow && (
                <Tooltip title={'Editar'}>
                  <EditIcon />
                </Tooltip>
              )}
            </Box>
            <Box>
              {!disabledEditableRow && (
                <Tooltip title={'Aceptar'}>
                  <CheckIcon
                    color={(!isDirty && !disabledEditableRow) || isLoading ? 'info' : 'success'}
                  />
                </Tooltip>
              )}
            </Box>
          </IconButton>
          <Box sx={{ width: 40 }}>
            {!disabledEditableRow && (
              <Tooltip title={'Cancelar'}>
                <IconButton
                  data-testid={`cancel-button-${index}`}
                  onClick={() => {
                    reset(), setDisabledEditableRow(!disabledEditableRow);
                  }}
                  disabled={isLoading}
                >
                  <CloseIcon color={isLoading ? 'info' : 'error'} />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </>
      )}
      {customIconText && (
        <Tooltip title={customIconText}>
          <Button
            data-testid={`admin-button-${index}`}
            onClick={() => handleCustomIcon(row._id)}
            disabled={isLoading}
          >
            <Text variant="body2Underline" color={isLoading ? 'GrayText' : 'secondary'}>
              {customIconText}
            </Text>
          </Button>
        </Tooltip>
      )}
      {linkIcon && (
        <>
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={openSnackbar}
            autoHideDuration={2600}
            TransitionComponent={TransitionUp}
            onClose={handleCloseSnackbar}
            sx={{ width: '40%', minWidth: '320px' }}
          >
            <Alert
              onClose={handleCloseSnackbar}
              sx={{
                width: '100%',
                backgroundColor: 'success.main',
                textAlign: 'center',
                fontSize: '16px',
                color: 'white',
              }}
              iconMapping={{
                success: <CheckCircleOutlineIcon fontSize="inherit" sx={{ color: 'white' }} />,
              }}
            >
              Link copiado al portapapeles
            </Alert>
          </Snackbar>
          <Button data-testid={`link-button-${index}`} onClick={handleLinkClick}>
            <Tooltip title="Copiar link público" enterTouchDelay={100}>
              <LinkIcon sx={{ color: '#0288D1' }} />
            </Tooltip>
          </Button>
        </>
      )}
      {editIcon && (
        <Tooltip title={'Editar'}>
          <IconButton
            data-testid={`edit-button-${index}`}
            onClick={() => handleEdit(row?.postulant?.dni ? row.postulant.dni : row._id)}
            disabled={isLoading}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      )}
      {duplicateIcon && (
        <Tooltip title={'Duplicar'}>
          <IconButton
            data-testid={`duplicate-button-${index}`}
            onClick={() => handleDuplicate(row._id)}
            disabled={isLoading}
          >
            <ContentCopyIcon />
          </IconButton>
        </Tooltip>
      )}
      {deleteIcon && (
        <Tooltip title={'Eliminar'}>
          <IconButton
            data-testid={`delete-button-${index}`}
            onClick={() => handleDelete(row?._id)}
            disabled={isLoading || disableDeleteIcon || !courseIsActive.isActive}
          >
            <DeleteIcon
              color={disableDeleteIcon || isLoading || !courseIsActive.isActive ? 'info' : 'error'}
            />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

export default ActionItems;
