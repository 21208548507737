import { Reducer } from 'redux';

import { entityInitialState } from 'src/constants/redux';

import { Actions, ActionsType, State } from './types';

const initialState: State = {
  content: undefined,
  contents: [],
  answer: '',
  comment: undefined,
  isLoading: false,
  ...entityInitialState,
};

const contentReducer: Reducer<State, ActionsType> = (state = initialState, action): State => {
  switch (action.type) {
    case Actions.GET_CONTENTS_FETCHING:
    case Actions.GET_CONTENT_BY_ID_FETCHING:
    case Actions.CREATE_CONTENT_FETCHING:
    case Actions.EDIT_CONTENT_FETCHING:
    case Actions.DELETE_CONTENT_FETCHING:
    case Actions.POST_ANSWER_FETCHING:
    case Actions.PATCH_COMMENT_FETCHING:
      return {
        ...state,
        isLoading: true,
      };
    case Actions.GET_CONTENTS_SUCCESS:
      return {
        ...state,
        contents: action.payload.data,
        isLoading: false,
        errorData: initialState.errorData,
      };
    case Actions.GET_CONTENT_BY_ID_SUCCESS:
      return {
        ...state,
        content: action.payload.data,
        isLoading: false,
        errorData: initialState.errorData,
      };
    case Actions.CREATE_CONTENT_SUCCESS:
    case Actions.EDIT_CONTENT_SUCCESS:
      return {
        ...state,
        content: action.payload.data,
        isLoading: false,
        errorData: initialState.errorData,
      };
    case Actions.DELETE_CONTENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case Actions.CLEAR_CONTENT:
      return {
        ...state,
        isLoading: false,
        content: initialState.content,
      };
    case Actions.GET_CONTENTS_ERROR:
    case Actions.GET_CONTENT_BY_ID_ERROR:
    case Actions.CREATE_CONTENT_ERROR:
    case Actions.EDIT_CONTENT_ERROR:
    case Actions.DELETE_CONTENT_ERROR:
      return {
        ...state,
        contents: initialState.contents,
        isLoading: false,
        errorData: action.payload,
      };
    case Actions.POST_ANSWER_SUCCESS:
      return {
        ...state,
        answer: action.payload.data,
        pagination: initialState.pagination,
        isLoading: false,
        errorData: initialState.errorData,
      };
    case Actions.PATCH_COMMENT_SUCCESS:
      return {
        ...state,
        comment: action.payload.data,
        isLoading: false,
        errorData: initialState.errorData,
      };
    case Actions.POST_ANSWER_ERROR:
      return {
        ...state,
        answer: initialState.answer,
        isLoading: false,
        errorData: action.payload,
        pagination: initialState.pagination,
      };
    case Actions.PATCH_COMMENT_ERROR:
      return {
        ...state,
        comment: initialState.comment,
        isLoading: false,
        errorData: action.payload,
      };
    default:
      return state;
  }
};

export default contentReducer;
