import React, { useCallback } from 'react';
import { FieldValues, useController } from 'react-hook-form';
import { TextField } from '@mui/material';

import setInputBoxShadow from 'src/utils/styles';

import { InputFileProps } from './types';

const InputFile = <TFormValues extends FieldValues>({
  name,
  control,
  defaultValue,
  placeholderColor = '#ffffff',
  fullWidth = true,
  showError = true,
  rules = undefined,
  type = 'file',
  ...props
}: InputFileProps<TFormValues>): JSX.Element => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control, defaultValue, rules });

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files[0];
      field.onChange(file);
    },
    [field],
  );

  return (
    <TextField
      {...props}
      type={type}
      onChange={handleOnChange}
      fullWidth={fullWidth}
      helperText={showError && (error?.message != undefined ? error?.message : ' ')}
      error={showError && error?.message != undefined}
      data-testid={`${name}-field`}
      inputProps={{
        ...props.inputProps,
        accept: 'application/pdf',
        style: setInputBoxShadow(placeholderColor),
      }}
      sx={{ '& label': { zIndex: 1 } }}
    />
  );
};

export default InputFile;
