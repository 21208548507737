import React from 'react';
import { FieldValues, useController } from 'react-hook-form';
import { TextField } from '@mui/material';

import setInputBoxShadow from 'src/utils/styles';

import { InputTextProps } from './types';

const InputText = <TFormValues extends FieldValues>({
  name,
  control,
  defaultValue,
  placeholderColor = '#ffffff',
  fullWidth = true,
  showError = true,
  rules = undefined,
  setError,
  dniInput,
  ...props
}: InputTextProps<TFormValues>): JSX.Element => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control, defaultValue, rules });

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    field.onBlur();
    let newValue = e.target.value.trim();
    if (e.target.type === 'number' && name.includes('correctedCriteria')) {
      const parsedValue = parseFloat(newValue);
      if (!isNaN(parsedValue)) {
        let roundedValue = Math.round(parsedValue);
        if (roundedValue > 10) {
          roundedValue = 10;
        }
        if (roundedValue < 1) {
          roundedValue = 1;
        }
        newValue = roundedValue.toString();
      } else newValue = '1';
    }
    if (name.includes('dni') && dniInput.postulantDni !== e.target.value.trim()) {
      dniInput.setDniFound('none');
      dniInput.setOnCreateAccount(false);
    }
    field.onChange(newValue);
    props?.onBlur && props.onBlur(e);
  };
  setError && setError(error?.message || '');

  return (
    <TextField
      {...field}
      {...props}
      onBlur={handleOnBlur}
      fullWidth={fullWidth}
      helperText={showError && (error?.message != undefined ? error?.message : ' ')}
      error={showError && error?.message != undefined}
      data-testid={`${name}-field`}
      inputProps={{ ...props.inputProps, style: setInputBoxShadow(placeholderColor) }}
      sx={{ '& label': { zIndex: 1 } }}
    />
  );
};

export default InputText;
