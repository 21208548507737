import _ from 'lodash';
import { ThunkDispatch } from 'redux-thunk';
import { ActionType } from 'typesafe-actions';

import { RootReducer } from '../types';
import * as actions from './actions';
import {
  editReportByIdRequest,
  editReportMarks,
  getReportsByCourseIdRequest,
  getReportsByGroupIdRequest,
  getReportsByModuleIdRequest,
} from './api';

export const getReportsByCourseId = (id: string, query: string) => {
  return async (dispatch: ThunkDispatch<RootReducer, null, ActionType<typeof actions>>) => {
    dispatch(actions.getReportsByCourseId.request(''));
    try {
      const response = await getReportsByCourseIdRequest({ id, query });
      if (response.error) {
        throw response;
      }
      return dispatch(
        actions.getReportsByCourseId.success({
          data: response.data,
          pagination: response.pagination,
        }),
      );
    } catch (error) {
      return dispatch(actions.getReportsByCourseId.failure(error));
    }
  };
};

export const getReportsByModuleId = (id: string, moduleId: string, query: string) => {
  return async (dispatch: ThunkDispatch<RootReducer, null, ActionType<typeof actions>>) => {
    dispatch(actions.getReportsByModuleId.request(''));
    try {
      const response = await getReportsByModuleIdRequest({ id, query }, moduleId);
      if (response.error) {
        throw response;
      }
      return dispatch(
        actions.getReportsByModuleId.success({
          data: response.data,
          pagination: response.pagination,
        }),
      );
    } catch (error) {
      return dispatch(actions.getReportsByModuleId.failure(error));
    }
  };
};

export const getReportsByGroupId = (id: string, groupId: string) => {
  return async (dispatch: ThunkDispatch<RootReducer, null, ActionType<typeof actions>>) => {
    dispatch(actions.getReportsByGroupId.request(''));
    try {
      const response = await getReportsByGroupIdRequest({ id }, groupId);
      if (response.error) {
        throw response;
      }

      const modulesContents = response.data.reduce((moduleList, report) => {
        const module = moduleList.find((module) => module._id === report.module._id);
        const reportContents = report.contentAnswer.map((contentAnswer) => contentAnswer.content);
        if (!module) {
          return [
            ...moduleList,
            {
              ...report.module,
              contents: reportContents,
            },
          ];
        } else {
          const uniqueData = [...module.contents, ...reportContents].reduce((result, current) => {
            if (!result[current._id]) {
              result[current._id] = current;
            }
            return result;
          }, {});

          return [...moduleList, uniqueData];
        }
      }, []);

      return dispatch(
        actions.getReportsByGroupId.success({
          data: response.data,
          pagination: response.pagination,
          modulesContents,
        }),
      );
    } catch (error) {
      return dispatch(actions.getReportsByGroupId.failure(error));
    }
  };
};

export const editReportById = (id: string, moduleId: string, data) => {
  return async (
    dispatch: ThunkDispatch<RootReducer, null, ActionType<typeof actions>>,
    getState: () => RootReducer,
  ) => {
    dispatch(actions.editReportById.request(''));
    try {
      const response = await editReportByIdRequest({ id, data });
      const reportState = getState().report;
      if (response.error) {
        throw response;
      }
      await dispatch(
        getReportsByModuleId(
          id,
          moduleId,
          `&page=${reportState.pagination.page}&limit=${reportState.pagination.limit}${
            reportState.filterQuery.length ? reportState.filterQuery : null
          }`,
        ),
      );
      return dispatch(actions.editReportById.success(''));
    } catch (error) {
      return dispatch(actions.editReportById.failure(error));
    }
  };
};

export const editReportMark = (courseId: string, reportId: string, contentId: string, data) => {
  return async (dispatch: ThunkDispatch<RootReducer, null, ActionType<typeof actions>>) => {
    dispatch(actions.editReportMark.request(''));
    try {
      const response = await editReportMarks(courseId, reportId, contentId, { data: data });
      if (response.error) {
        throw response;
      }
      return dispatch(actions.editReportMark.success(response));
    } catch (error) {
      return dispatch(actions.editReportMark.failure(error));
    }
  };
};

export const clearError = () => {
  return (dispatch: ThunkDispatch<RootReducer, null, ActionType<typeof actions>>) => {
    dispatch(actions.clearError());
  };
};
