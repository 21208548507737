import React, { ReactElement } from 'react';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { ToolbarProps, ToolbarSlot } from '@react-pdf-viewer/default-layout';
import { themePlugin } from '@react-pdf-viewer/theme';

import { useAppDispatch } from 'src/redux';
import { hideFileModal } from 'src/redux/modules/ui/actions';

const RenderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => {
  const dispatch = useAppDispatch();
  const handleClose = () => {
    dispatch(hideFileModal());
  };
  return (
    <Toolbar>
      {(slots: ToolbarSlot) => {
        const themePluginInstance = themePlugin();
        const { SwitchThemeButton } = themePluginInstance;
        const { Zoom, ZoomIn, ZoomOut } = slots;
        return (
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              width: '100%',
            }}
          >
            <div style={{ padding: '0px 8px', justifySelf: 'flex-start' }} onClick={handleClose}>
              <ArrowBackRoundedIcon />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                paddingRight: '40px',
              }}
            >
              <div style={{ padding: '0px 2px' }}>
                <ZoomOut />
              </div>
              <div style={{ padding: '0px 2px' }}>
                <Zoom levels={[0.8, 1.2, 1.6]} />
              </div>
              <div style={{ padding: '0px 2px' }}>
                <ZoomIn />
              </div>
              <div>
                <SwitchThemeButton />
              </div>
            </div>
          </div>
        );
      }}
    </Toolbar>
  );
};

export default RenderToolbar;
