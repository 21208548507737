import { ActionType } from 'typesafe-actions';

import { CourseUser } from 'src/interfaces/entities/course-user';

import { AsyncState, Params } from '../types';
import * as actions from './actions';
import * as thunks from './thunks';

export interface State extends AsyncState {
  courseUser: CourseUser;
  courseUsers: CourseUser[];
  filterQuery: string;
}

export enum Actions {
  GET_USERS_BY_COURSE_ID_FETCHING = 'GET_USERS_BY_COURSE_ID_FETCHING',
  GET_USERS_BY_COURSE_ID_SUCCESS = 'GET_USERS_BY_COURSE_ID_SUCCESS',
  GET_USERS_BY_COURSE_ID_ERROR = 'GET_USERS_BY_COURSE_ID_ERROR',
  DISABLE_BY_USER_ID_FETCHING = 'DISABLE_BY_USER_ID_FETCHING',
  DISABLE_BY_USER_ID_SUCCESS = 'DISABLE_BY_USER_ID_SUCCESS',
  DISABLE_BY_USER_ID_ERROR = 'DISABLE_BY_USER_ID_ERROR',
  ADD_COURSE_USERS_FETCHING = 'ADD_COURSE_USERS_FETCHING',
  ADD_COURSE_USERS_SUCCESS = 'ADD_COURSE_USERS_SUCCESS',
  ADD_COURSE_USERS_ERROR = 'ADD_COURSE_USERS_ERROR',
  SET_COURSE_USERS_QUERY = 'SET_COURSE_USERS_QUERY',
  RESET_COURSE_USERS_QUERY = 'RESET_COURSE_USERS_QUERY',
  GET_USERS_WITHOUT_GROUP_FETCHING = 'GET_USERS_WITHOUT_GROUP_FETCHING',
  GET_USERS_WITHOUT_GROUP_SUCCESS = 'GET_USERS_WITHOUT_GROUP_SUCCESS',
  GET_USERS_WITHOUT_GROUP_ERROR = 'GET_USERS_WITHOUT_GROUP_ERROR',
}

export type ActionsType = ActionType<typeof actions | typeof thunks>;

export interface CourseUserParams extends Params<CourseUser> {
  courseId?: string;
  userId?: string;
}
