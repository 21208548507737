import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import ReplayIcon from '@mui/icons-material/Replay';
import SearchIcon from '@mui/icons-material/Search';
import { Box, IconButton, Tooltip } from '@mui/material';

import { Dropdown, InputText } from 'src/components/shared/ui';
import { NumberKeys } from 'src/constants/validation-messages';
import { RootReducer } from 'src/redux/modules/types';

import { PostulantCourseFilter } from '../postulant-course/types';
import { TableFilterProps } from '../types';
import styles from './postulant-course-filters.module.css';
import { PostulantCourseFilterWithoutPromotion } from './types';

const PostulantCourseUserTableNotQualifyFilters = ({
  onFiltersSubmit,
  isLoading,
}: Omit<TableFilterProps<PostulantCourseFilter>, 'filter'>) => {
  const { filterQuery } = useSelector((state: RootReducer) => state.postulantCourse);
  const { registrationForm } = useSelector((state: RootReducer) => state.registrationForm);
  const urlParams = new URLSearchParams(filterQuery.replace(/\./g, '_'));
  const objectFromParams = { ...Object.fromEntries(urlParams) };

  const { handleSubmit, control, reset } = useForm<PostulantCourseFilterWithoutPromotion>({
    defaultValues: {
      postulant_firstName: '',
      postulant_lastName: '',
      postulant_country: '',
      postulant_age_min: '',
      postulant_age_max: '',
      view: '',
      ...objectFromParams,
    },
    mode: 'onSubmit',
  });

  const viewOptions = useMemo(
    () =>
      registrationForm
        ? [
            { value: '', label: 'Ninguno' },
            ...registrationForm.views.map((view) => ({
              value: view._id,
              label: view.name,
            })),
          ]
        : [{ value: '', label: 'Ninguno' }],
    [registrationForm],
  );

  return (
    <form className={styles.filtersContainer} onSubmit={handleSubmit(onFiltersSubmit)}>
      <Box className={styles.marginRight10}>
        <InputText
          control={control}
          name="postulant_firstName"
          label="Nombre"
          variant="outlined"
          fullWidth={false}
          size="small"
          showError={false}
          disabled={isLoading}
          InputProps={{
            endAdornment: (
              <Tooltip title={'Buscar'}>
                <IconButton type="submit" disabled={isLoading}>
                  <SearchIcon />
                </IconButton>
              </Tooltip>
            ),
          }}
        />
      </Box>
      <Box className={styles.marginRight10}>
        <InputText
          control={control}
          name="postulant_lastName"
          label="Apellido"
          variant="outlined"
          fullWidth={false}
          size="small"
          showError={false}
          disabled={isLoading}
          InputProps={{
            endAdornment: (
              <Tooltip title={'Buscar'}>
                <IconButton type="submit" disabled={isLoading}>
                  <SearchIcon />
                </IconButton>
              </Tooltip>
            ),
          }}
        />
      </Box>
      <Box className={styles.marginRight10}>
        <InputText
          control={control}
          name="postulant_country"
          label="País"
          variant="outlined"
          fullWidth={false}
          size="small"
          showError={false}
          disabled={isLoading}
          InputProps={{
            endAdornment: (
              <Tooltip title={'Buscar'}>
                <IconButton type="submit" disabled={isLoading}>
                  <SearchIcon />
                </IconButton>
              </Tooltip>
            ),
          }}
        />
      </Box>
      <Box className={`${styles.marginRight10} ${styles.numberInputContainer}`}>
        <InputText
          control={control}
          name="postulant_age_min"
          label="Edad min."
          type="number"
          variant="outlined"
          fullWidth={false}
          size="small"
          showError={false}
          disabled={isLoading}
          onKeyDown={(e) => !NumberKeys.includes(e.key) && e.preventDefault()}
        />
      </Box>
      <Box className={`${styles.marginRight10} ${styles.numberInputContainer}`}>
        <InputText
          control={control}
          name="postulant_age_max"
          label="Edad máx."
          type="number"
          variant="outlined"
          fullWidth={false}
          size="small"
          showError={false}
          disabled={isLoading}
          onKeyDown={(e) => !NumberKeys.includes(e.key) && e.preventDefault()}
        />
      </Box>
      <Box className={`${styles.marginRight10} ${styles.dropdownContainer}`}>
        <Dropdown
          control={control}
          name="view"
          label="Tipo de Formulario"
          options={viewOptions}
          variant="outlined"
          showError={false}
          size="small"
          disabled={isLoading}
          onOptionClick={() => {
            handleSubmit(onFiltersSubmit)();
          }}
        />
      </Box>
      <Tooltip title={'Reiniciar'}>
        <Box className={styles.marginRight10}>
          <IconButton
            disabled={isLoading}
            onClick={() => {
              reset();
              handleSubmit(onFiltersSubmit)();
            }}
          >
            <ReplayIcon />
          </IconButton>
        </Box>
      </Tooltip>
      <input type="submit" hidden />
    </form>
  );
};

export default PostulantCourseUserTableNotQualifyFilters;
