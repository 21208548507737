import { Reducer } from 'redux';

import { Actions, ActionsType, uiState } from './types';

const initialState: uiState = {
  modal: {
    title: '',
    description: '',
    open: false,
    type: 'alert',
  },
  snackbar: {
    title: '',
    message: '',
    open: false,
    severity: 'success',
  },
  fileModal: {
    url: '',
    open: false,
    type: '',
  },
};

const uiReducer: Reducer<uiState, ActionsType> = (state = initialState, action): uiState => {
  switch (action.type) {
    case Actions.OPEN_MODAL:
      return {
        ...state,
        modal: {
          ...action.payload,
          open: true,
        },
      };
    case Actions.HIDE_MODAL:
      return {
        ...state,
        modal: {
          ...state.modal,
          open: false,
        },
      };
    case Actions.OPEN_SNACKBAR:
      return {
        ...state,
        snackbar: {
          ...action.payload,
          open: true,
        },
      };
    case Actions.HIDE_SNACKBAR:
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          open: false,
        },
      };
    case Actions.OPEN_FILE_MODAL:
      return {
        ...state,
        fileModal: {
          ...action.payload,
          open: true,
        },
      };
    case Actions.HIDE_FILE_MODAL:
      return {
        ...state,
        fileModal: {
          ...state.fileModal,
          open: false,
        },
      };
    default:
      return state;
  }
};

export default uiReducer;
