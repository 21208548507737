import { ActionType } from 'typesafe-actions';

import { ContentType } from 'src/interfaces/entities/content';
import { ContentAnswer } from 'src/interfaces/entities/report';

import { AsyncState } from '../types';
import * as actions from './actions';
import * as thunks from './thunks';

export interface State extends AsyncState {
  content: ContentType;
  contents: ContentType[];
  answer: ContentAnswer['answer'];
  comment: ContentAnswer['comments'];
}

export enum Actions {
  GET_CONTENTS_FETCHING = 'GET_CONTENTS_FETCHING',
  GET_CONTENTS_SUCCESS = 'GET_CONTENTS_SUCCESS',
  GET_CONTENTS_ERROR = 'GET_CONTENTS_ERROR',
  GET_CONTENT_BY_ID_FETCHING = 'GET_CONTENT_BY_ID_FETCHING',
  GET_CONTENT_BY_ID_SUCCESS = 'GET_CONTENT_BY_ID_SUCCESS',
  GET_CONTENT_BY_ID_ERROR = 'GET_CONTENT_BY_ID_ERROR',
  CREATE_CONTENT_FETCHING = 'CREATE_CONTENT_FETCHING',
  CREATE_CONTENT_SUCCESS = 'CREATE_CONTENT_SUCCESS',
  CREATE_CONTENT_ERROR = 'CREATE_CONTENT_ERROR',
  EDIT_CONTENT_FETCHING = 'EDIT_CONTENT_FETCHING',
  EDIT_CONTENT_SUCCESS = 'EDIT_CONTENT_SUCCESS',
  EDIT_CONTENT_ERROR = 'EDIT_CONTENT_ERROR',
  DELETE_CONTENT_FETCHING = 'DELETE_CONTENT_FETCHING',
  DELETE_CONTENT_SUCCESS = 'DELETE_CONTENT_SUCCESS',
  DELETE_CONTENT_ERROR = 'DELETE_CONTENT_ERROR',
  PATCH_COMMENT_FETCHING = 'PATCH_COMMENT_FETCHING',
  PATCH_COMMENT_SUCCESS = 'PATCH_COMMENT_SUCCESS',
  PATCH_COMMENT_ERROR = 'PATCH_COMMENT_ERROR',
  POST_ANSWER_FETCHING = 'POST_ANSWER_FETCHING',
  POST_ANSWER_SUCCESS = 'POST_ANSWER_SUCCESS',
  POST_ANSWER_ERROR = 'POST_ANSWER_ERROR',
  CLEAR_CONTENT = 'CLEAR_CONTENT',
}

export type ActionsType = ActionType<typeof actions | typeof thunks>;
