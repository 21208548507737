import React from 'react';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import {
  Box,
  Checkbox,
  IconButton,
  styled,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';

import { Text } from 'src/components/shared/ui';
import { wrapName } from 'src/utils/formatters';

import { CustomTableHeadProps } from '../../types';
import TableHeadCellGroup from '../head-cell-group';
import TableSecondaryCellGroup from '../secondary-cell-group';
import styles from './table-head.module.css';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.tableHead.main,
    borderBottom: 'none',
  },
}));

const CustomTableHead = ({
  onSelectAllClick,
  numSelected,
  rowCount,
  checkboxes,
  headCells,
  headCellGroups,
  secondaryCellGroups,
  style,
  handleRefresh,
  isLoading,
  cellContentLength,
  maxCellContentLength,
  isSticky,
}: CustomTableHeadProps) => {
  const measureSticky = isLoading ? 0 : !headCellGroups ? 0 : secondaryCellGroups ? 120 : 60;
  return (
    <TableHead data-testid="table-head">
      {!isLoading && headCellGroups && (
        <TableHeadCellGroup
          headCells={headCells}
          headCellGroups={headCellGroups}
          cellContentLength={cellContentLength}
          maxCellContentLength={maxCellContentLength}
          isLoading={isLoading}
        />
      )}
      {!isLoading && secondaryCellGroups && (
        <TableSecondaryCellGroup
          headCells={headCells}
          secondaryCellGroups={secondaryCellGroups}
          cellContentLength={cellContentLength}
          maxCellContentLength={maxCellContentLength}
          isLoading={isLoading}
        />
      )}
      <TableRow style={style}>
        {checkboxes && (
          <StyledTableCell
            className={
              !isLoading && isSticky ? styles.stickyCheckbox : styles.tablePaddingLeftCheckbox
            }
            sx={{ top: measureSticky }}
          >
            <Checkbox
              sx={{
                color: '#FFFFFF',
                '&.Mui-checked': { color: '#FFFFFF' },
                '&.MuiCheckbox-indeterminate': { color: '#FFFFFF' },
              }}
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </StyledTableCell>
        )}
        {headCells.map((headCell, index) => (
          <StyledTableCell
            className={`${
              !isLoading && headCell.id === 'firstName' && isSticky
                ? styles.stickyName
                : !isLoading && headCell.id === 'lastName' && isSticky
                ? styles.stickyLastName
                : !checkboxes && index === 0 && styles.tablePaddingLeftCell
            }`}
            key={headCell.id}
            align={headCell.align ? headCell.align : 'left'}
            colSpan={headCell?.columns}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sx={
              !isLoading && headCellGroups && headCell.border
                ? headCell.border === 'left'
                  ? {
                      borderRight: 1,
                      borderTop: 1,
                      borderColor: 'white',
                      position: 'sticky',
                      top: measureSticky,
                    }
                  : {
                      borderRight: 1,
                      borderColor: 'white',
                      position: 'sticky',
                      top: measureSticky,
                    }
                : !isLoading && !secondaryCellGroups && headCellGroups
                ? {
                    position: 'sticky',
                    top: measureSticky,
                    zIndex: 100,
                  }
                : null
            }
          >
            <Box>
              <Text variant="headerTable">
                {wrapName(headCell.label, cellContentLength, maxCellContentLength)}
              </Text>
            </Box>
            {headCell.subLabel && (
              <Text variant="subtitle2" color="white">
                {wrapName(headCell.subLabel, cellContentLength, maxCellContentLength)}
              </Text>
            )}
          </StyledTableCell>
        ))}
        <StyledTableCell
          align="right"
          sx={
            !isLoading && isSticky
              ? { position: 'sticky', top: measureSticky, zIndex: '100' }
              : !isSticky && { position: 'sticky', top: measureSticky }
          }
        >
          {handleRefresh && (
            <Tooltip title={'Actualizar'}>
              <IconButton disabled={isLoading} onClick={handleRefresh}>
                <RefreshOutlinedIcon htmlColor="white" />
              </IconButton>
            </Tooltip>
          )}
        </StyledTableCell>
      </TableRow>
    </TableHead>
  );
};
export default CustomTableHead;
