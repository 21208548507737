import React, { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { tokenListener } from 'src/utils/token-listener';

import { router } from './routes';

const App = (): JSX.Element => {
  useEffect(() => {
    tokenListener();
  }, []);

  return <RouterProvider router={router} />;
};

export default Sentry.withProfiler(App);
