import React from 'react';
import { useForm } from 'react-hook-form';
import ReplayIcon from '@mui/icons-material/Replay';
import SearchIcon from '@mui/icons-material/Search';
import { Box, IconButton, Tooltip } from '@mui/material';

import { Dropdown, InputText } from 'src/components/shared/ui';
import { useAppSelector } from 'src/redux';
import { RootReducer } from 'src/redux/modules/types';

import { TableFilterProps } from '../types';
import { UserFilters } from './types';
import styles from './user-filters.module.css';

const UserTableFilters = ({
  onFiltersSubmit,
  isLoading,
}: Omit<TableFilterProps<UserFilters>, 'filter'>) => {
  const { filterQuery } = useAppSelector((state: RootReducer) => state.user);
  const urlParams = new URLSearchParams(filterQuery.replace(/postulant./g, 'postulant_'));
  const objectFromParams = Object.fromEntries(urlParams);

  const { handleSubmit, control, reset } = useForm<UserFilters>({
    defaultValues: {
      postulant_dni: '',
      email: '',
      postulant_firstName: '',
      postulant_lastName: '',
      isActive: '',
      isInternal: '',
      ...objectFromParams,
    },
    mode: 'onSubmit',
  });

  return (
    <form
      data-testid="user-filter-container-form"
      className={styles.filtersContainer}
      onSubmit={handleSubmit(onFiltersSubmit)}
    >
      <Box className={styles.marginRight10}>
        <InputText
          control={control}
          name="postulant_firstName"
          label="Nombre"
          variant="outlined"
          fullWidth={false}
          size="small"
          showError={false}
          disabled={isLoading}
          InputProps={{
            endAdornment: (
              <Tooltip title={'Buscar'}>
                <IconButton type="submit" disabled={isLoading}>
                  <SearchIcon />
                </IconButton>
              </Tooltip>
            ),
          }}
        />
      </Box>
      <Box className={styles.marginRight10}>
        <InputText
          control={control}
          name="postulant_lastName"
          label="Apellido"
          variant="outlined"
          fullWidth={false}
          size="small"
          showError={false}
          disabled={isLoading}
          InputProps={{
            endAdornment: (
              <Tooltip title={'Buscar'}>
                <IconButton type="submit" disabled={isLoading}>
                  <SearchIcon />
                </IconButton>
              </Tooltip>
            ),
          }}
        />
      </Box>
      <Box className={styles.marginRight10}>
        <InputText
          control={control}
          name="postulant_dni"
          label="DNI"
          variant="outlined"
          fullWidth={false}
          size="small"
          showError={false}
          disabled={isLoading}
          InputProps={{
            endAdornment: (
              <Tooltip title={'Buscar'}>
                <IconButton type="submit" disabled={isLoading}>
                  <SearchIcon />
                </IconButton>
              </Tooltip>
            ),
          }}
        />
      </Box>
      <Box className={styles.marginRight10}>
        <InputText
          control={control}
          name="email"
          label="Mail"
          variant="outlined"
          fullWidth={false}
          size="small"
          showError={false}
          disabled={isLoading}
          InputProps={{
            endAdornment: (
              <Tooltip title={'Buscar'}>
                <IconButton type="submit" disabled={isLoading}>
                  <SearchIcon />
                </IconButton>
              </Tooltip>
            ),
          }}
        />
      </Box>
      <Box className={`${styles.dropdownContainer} ${styles.marginRight10}`}>
        <Dropdown
          options={[
            { value: '', label: 'Ninguno' },
            { value: 'true', label: 'Activo' },
            { value: 'false', label: 'Inactivo' },
          ]}
          control={control}
          name="isActive"
          label="Estado"
          variant="outlined"
          showError={false}
          size="small"
          placeholder="isActiveStatus"
          disabled={isLoading}
          onOptionClick={() => {
            handleSubmit(onFiltersSubmit)();
          }}
        />
      </Box>
      <Box className={`${styles.dropdownContainer} ${styles.marginRight10}`}>
        <Dropdown
          options={[
            { value: '', label: 'Ninguno' },
            { value: 'true', label: 'Empleado' },
            { value: 'false', label: 'Estudiante' },
          ]}
          control={control}
          name="isInternal"
          label="Tipo"
          variant="outlined"
          showError={false}
          size="small"
          placeholder="Status"
          disabled={isLoading}
          onOptionClick={() => {
            handleSubmit(onFiltersSubmit)();
          }}
        />
      </Box>
      <Tooltip title={'Reiniciar'}>
        <Box className={styles.marginRight10}>
          <IconButton
            disabled={isLoading}
            onClick={() => {
              reset();
              handleSubmit(onFiltersSubmit)();
            }}
          >
            <ReplayIcon />
          </IconButton>
        </Box>
      </Tooltip>
      <input type="submit" hidden />
    </form>
  );
};

export default UserTableFilters;
