export enum StatusCodes {
  OK = 200,
  CREATED = 201,
  ACCEPTED = 202,
  NO_CONTENT = 204,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
}

export enum ApiErrorMessages {
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
}

export enum PublicFormTypeErrors {
  INSCRIPTION_PROCESS_END = 'INSCRIPTION_PROCESS_END',
  COURSE_NOT_STARTED = 'COURSE_NOT_STARTED',
}
