import { action } from 'typesafe-actions';

import { Actions, uiState } from './types';

export const openModal = (props: uiState['modal']) => action(Actions.OPEN_MODAL, props);

export const openSnackbar = (props: uiState['snackbar']) => action(Actions.OPEN_SNACKBAR, props);

export const hideSnackbar = () => action(Actions.HIDE_SNACKBAR);

export const hideModal = () => action(Actions.HIDE_MODAL);

export const openFileModal = (props: uiState['fileModal']) =>
  action(Actions.OPEN_FILE_MODAL, props);

export const hideFileModal = () => action(Actions.HIDE_FILE_MODAL);
