import { format } from 'date-fns';
import React from 'react';
import { Box } from '@mui/material';

import { Text } from 'src/components/shared/ui';

import styles from './comments.module.css';
import { CommentsProps } from './types';

const Comments = ({ comments }: CommentsProps) => {
  const orderedComments = comments.sort((a, b) => {
    const dateA = new Date(a.createdAt).getTime();
    const dateB = new Date(b.createdAt).getTime();
    return dateA - dateB;
  });
  return (
    <Box className={styles.commentsContainer}>
      {orderedComments.map((comments, index) => {
        const date = new Date(comments.createdAt);
        const formatDate = format(date, 'dd/MM/yyyy HH:mm');
        return (
          <div
            key={index}
            className={comments.author.role === 'STUDENT' ? styles.commentsContainerStudent : ''}
          >
            <Box
              className={
                comments.author.role === 'STUDENT' ? styles.commentsStudent : styles.commentsTutor
              }
            >
              <Text
                className={comments.author.role === 'STUDENT' ? styles.student : styles.tutor}
                sx={{ color: 'primary', fontWeight: '800' }}
              >
                {comments.author.firstName} {comments.author.lastName}{' '}
              </Text>
              <Text sx={{ color: 'primary', fontWeight: '800' }} />
              <Text className={styles.description}>{comments.description}</Text>
              <Text sx={{ fontSize: '12px', color: '#00000088' }}>{formatDate}</Text>
            </Box>
          </div>
        );
      })}
    </Box>
  );
};

export default Comments;
