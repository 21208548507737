import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Checkbox, TableCell, TableRow } from '@mui/material';

import { InputText, Text } from 'src/components/shared/ui';
import { NumberKeys } from 'src/constants/validation-messages';
import { GeneralDataType } from 'src/interfaces';
import { wrapName } from 'src/utils/formatters';

import { CustomTableRowProps, EditableTableData, HeadCell } from '../../types';
import ActionItems from '../action-items';
import styles from './index.module.css';

const EditableTableRow = <DataType extends GeneralDataType>({
  headCells,
  row,
  isItemSelected,
  checkboxes,
  deleteIcon,
  editIcon,
  duplicateIcon,
  customIconText,
  handleEdit,
  handleDelete,
  handleDuplicate,
  handleCustomIcon,
  style,
  saveEditableText,
  onEditableSubmit,
  onInputChange,
  handleObjectCheckboxClick,
  index,
  onRowEditableSubmit,
  isRowEditable = false,
  editableProp,
  linkIcon,
  handleLinkIcon,
  isLoading,
  cellContentLength,
  maxCellContentLength,
}: CustomTableRowProps<DataType>): JSX.Element => {
  let disableDeleteIcon = false;
  let editable = false;
  const editableHeadCells = headCells.filter((headCell) => headCell.editable === true);
  const [disabled, setDisabled] = useState(editableHeadCells.length > 0 && !editableProp);
  const [disabledEditableRow, setDisabledEditableRow] = useState(onRowEditableSubmit !== undefined);

  const defaultValues: EditableTableData = editableHeadCells.reduce(
    (defaultValues, headCell) => ({
      ...defaultValues,
      row,
      [headCell.id]: editableProp ? row[headCell.id][editableProp] : '',
    }),
    {},
  );

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { isDirty },
    reset,
  } = useForm<EditableTableData>({
    mode: 'onBlur',
    defaultValues: defaultValues,
  });

  const onInputBlur = (e) => {
    if (
      (e.target.value < 1 && e.target.value !== '') ||
      (e.target.value == '' && editableProp !== undefined)
    ) {
      setValue(e.target.name, 1);
    } else if (e.target.value > 10 && e.target.value !== '') {
      setValue(e.target.name, 10);
    } else {
      setValue(e.target.name, e.target.value);
    }
    const rowInputs = getValues();
    const filledInputs =
      Object.entries(rowInputs).filter(
        (arr) =>
          arr[1] !== '' && ((Number(arr[1]) > 0 && Number(arr[1]) < 11) || isNaN(Number(arr[1]))),
      ).length - 1;
    if (
      editableHeadCells.length === filledInputs &&
      getValues(e.target.name) != row[e.target.name][editableProp]
    ) {
      setDisabled(false);
      onRowEditableSubmit === undefined && handleObjectCheckboxClick(row, 'check');
      handleSubmit(onInputChange)();
    } else {
      !disabledEditableRow && setDisabled(true);
      if (filledInputs === 0 || getValues(e.target.name) == row[e.target.name][editableProp]) {
        handleObjectCheckboxClick(row, 'uncheck');
      }
    }
  };

  return (
    <TableRow
      style={style}
      data-testid={`row-${index}`}
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      selected={isItemSelected}
    >
      {checkboxes && (
        <TableCell className={styles.tablePaddingLeftCheckbox}>
          <Checkbox
            color="primary"
            checked={isItemSelected}
            disabled={disabled || isLoading}
            onClick={() => {
              handleObjectCheckboxClick(row);
            }}
          />
        </TableCell>
      )}
      {headCells.map((headCell: HeadCell, index) => {
        editable = headCell.editable;
        const headId = headCell.id.toString();
        const headDots = headId.includes('.') ? headId.split('.') : [];
        let cellValue = headDots.length ? row : row[headCell.id];
        if (headDots.length) {
          for (let i = 0; i < headDots.length; i++) {
            if (typeof cellValue === 'string') {
              break;
            }
            if (typeof cellValue === 'object') {
              cellValue = cellValue[headDots[i]];
            }
          }
        }

        let cellElement: JSX.Element;
        if (headCell.cellElements?.length) {
          const chip = headCell.cellElements.find((cellElement) => cellElement.id === row[headId]);
          disableDeleteIcon = chip?.disableDeleteButton;
          cellElement = chip.element;
        }
        if (editable) {
          return (
            <TableCell key={index}>
              <Box className={styles.inputContainer}>
                <InputText
                  onBlur={(e) => onInputBlur(e)}
                  type="number"
                  control={control}
                  name={headCell.id}
                  size="small"
                  InputProps={{ inputProps: { min: 1, max: 10 } }}
                  showError={false}
                  fullWidth={false}
                  disabled={(disabledEditableRow && !isDirty) || isLoading}
                  onKeyDown={(e) => !NumberKeys.includes(e.key) && e.preventDefault()}
                />
              </Box>
            </TableCell>
          );
        }
        return (
          <TableCell
            key={index}
            align={headCell.align ? headCell.align : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            className={`${!checkboxes && index === 0 && styles.tablePaddingLeftCell}`}
          >
            {headCell.cellElements?.length ? (
              cellElement
            ) : (
              <Text data-testid={`column-${index}`}>
                {cellValue ? wrapName(cellValue, cellContentLength, maxCellContentLength) : '--'}
              </Text>
            )}
          </TableCell>
        );
      })}
      <TableCell>
        {(deleteIcon ||
          editIcon ||
          customIconText ||
          editable ||
          isRowEditable ||
          duplicateIcon) && (
          <ActionItems<DataType>
            handleSubmit={handleSubmit}
            onEditableSubmit={onEditableSubmit}
            disabled={disabled}
            isDirty={isDirty}
            disabledEditableRow={disabledEditableRow}
            isLoading={isLoading}
            saveEditableText={saveEditableText}
            index={index}
            onRowEditableSubmit={onRowEditableSubmit}
            reset={reset}
            setDisabledEditableRow={setDisabledEditableRow}
            customIconText={customIconText}
            linkIcon={linkIcon}
            handleLinkIcon={handleLinkIcon}
            editIcon={editIcon}
            deleteIcon={deleteIcon}
            duplicateIcon={duplicateIcon}
            handleCustomIcon={handleCustomIcon}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            handleDuplicate={handleDuplicate}
            row={row}
            disableDeleteIcon={disableDeleteIcon}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

export default EditableTableRow;
