import './index.css';
import '@fontsource/roboto';
import '@fontsource/inter';
import '@fontsource/raleway';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { Worker } from '@react-pdf-viewer/core';

import App from './app';
import { FileModal, Modal } from './components/shared/ui';
import CustomSnackbar from './components/shared/ui/snackbar';
import theme from './config/material-theme';
import store from './redux/store';
import reportWebVitals from './report-web-vitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.worker.min.js">
        <App />
      </Worker>
      <Modal />
      <CustomSnackbar />
      <FileModal />
    </Provider>
  </ThemeProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
