import apiClient from 'src/config/api';
import firebase from 'src/config/firebase';
import { UserRoutes } from 'src/constants/routes';
import { setAuthentication } from 'src/redux/modules/auth/actions';
import { initialState } from 'src/redux/modules/auth/reducer';
import { getMe } from 'src/redux/modules/auth/thunks';
import store from 'src/redux/store';
import { router } from 'src/routes';

export const firebaseIdTokenChanged = async (user) => {
  if (user) {
    const token = await user.getIdToken();
    const {
      claims: { userType, isNewUser },
    } = await user.getIdTokenResult();
    sessionStorage.setItem('token', token);
    apiClient.defaults.headers.common['token'] = token;
    store.dispatch(
      setAuthentication({
        userType,
        isNewUser,
        currentUid: user.uid,
      }),
    );
    sessionStorage.setItem('isLoading', 'false');
    if (userType === 'NORMAL') {
      await store.dispatch(getMe());
    }

    if (isNewUser) {
      router.navigate(UserRoutes.newPassword.route);
    } else {
      router.navigate(router.state.location.pathname);
    }
  } else {
    sessionStorage.removeItem('token');
    store.dispatch(setAuthentication(initialState.authenticated));
    sessionStorage.setItem('isLoading', 'false');
  }
};

export const tokenListener = () => {
  sessionStorage.setItem('isLoading', 'true');
  firebase.auth().onIdTokenChanged(firebaseIdTokenChanged);
};
