import * as React from 'react';

import styles from './video-player.module.css';
import { VideoPlayerProps } from './video-props';

const getYoutubeUrl = (link: string) => {
  let videoId: string = link.split('v=')[1];
  let embeddedLink: string;
  const ampersandPosition = videoId.indexOf('&');

  if (ampersandPosition != -1) {
    videoId = videoId.substring(0, ampersandPosition);
  }

  if (videoId.length) {
    embeddedLink = `https://www.youtube.com/embed/${videoId}?&rel=0&enablejsapi=1`;
  }

  return embeddedLink;
};

const VideoPlayer = ({ link, height, width }: VideoPlayerProps): JSX.Element => {
  const iframeRef = React.useRef<HTMLIFrameElement>(null);

  React.useEffect(() => {
    const iframe = iframeRef.current;
    const closeModal = () => {
      if (iframe) {
        iframe.contentWindow?.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
      }
    };
    document.addEventListener('click', closeModal);
    return () => {
      document.removeEventListener('click', closeModal);
    };
  }, []);

  return (
    <iframe
      className={styles.iFrameDiv}
      ref={iframeRef}
      width={width ? width : '100%'}
      height={height ? height : '100%'}
      src={getYoutubeUrl(link)}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
      style={{ overflow: 'hidden' }}
    ></iframe>
  );
};

export default VideoPlayer;
