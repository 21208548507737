import { ActionType } from 'typesafe-actions';

import * as actions from './actions';

export interface ModalState {
  open?: boolean;
  title?: string;
  description?: string | JSX.Element;
  type: 'alert' | 'confirm' | 'confirmForm';
  confirmButton?: string;
  handleConfirm?: () => void;
  handleOnClose?: () => void;
}

export interface SnackbarState {
  title: string;
  message: string;
  open?: boolean;
  severity: 'success' | 'error';
}

export interface ContentErrorType {
  errorType: string;
  message: SnackbarState;
}
export interface ModuleErrorType {
  errorType: string;
  message: SnackbarState;
}

export interface ModalFileState {
  open?: boolean;
  url?: string;
  type: 'video' | 'pdf' | '';
  handleOnClose?: () => void;
}

export interface uiState {
  modal: ModalState;
  snackbar: SnackbarState;
  fileModal: ModalFileState;
}

export enum Actions {
  OPEN_MODAL = 'OPEN_MODAL',
  HIDE_MODAL = 'HIDE_MODAL',
  OPEN_SNACKBAR = 'OPEN_SNACKBAR',
  HIDE_SNACKBAR = 'HIDE_SNACKBAR',
  OPEN_FILE_MODAL = 'OPEN_FILE_MODAL',
  HIDE_FILE_MODAL = 'HIDE_FILE_MODAL',
}

export type ActionsType = ActionType<typeof actions>;
