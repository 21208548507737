import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CustomButton, Text } from 'src/components/shared/ui';

import style from './error-boundaries.module.css';
import { ReactChildren } from './type';

const ErrorBoundary = ({ children }: ReactChildren) => {
  const [hasError, setHasError] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const errorHandler = () => {
      setHasError(true);
    };

    window.addEventListener('error', errorHandler);

    return () => {
      window.removeEventListener('error', errorHandler);
    };
  }, []);

  const goBack = () => {
    setHasError(false);
    navigate('/user');
  };

  const reload = () => {
    setHasError(false);
    location.reload();
  };

  if (hasError) {
    return (
      <div className={style.container}>
        <div className={style.errorContainer}>
          <Text className={style.textContainer} variant="h1">
            ¡Oops! Algo salió mal
          </Text>
          <Text className={style.textContainer} variant="h2">
            El equipo de desarrollo está trabajando para solucionar el error
          </Text>
          <div>
            <CustomButton
              sx={{ margin: '15px' }}
              onClick={goBack}
              className={style.button}
              variant="contained"
            >
              Volver al Inicio
            </CustomButton>
            <CustomButton
              sx={{ margin: '15px' }}
              onClick={reload}
              className={style.button}
              variant="outlined"
            >
              Recargar Página
            </CustomButton>
          </div>
        </div>
        <div className={style.backgroundImage}></div>
      </div>
    );
  }

  return <>{children}</>;
};

export default ErrorBoundary;
