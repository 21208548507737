import React from 'react';
import { TableRow } from '@mui/material';

import { Text } from 'src/components/shared/ui';
import { wrapName } from 'src/utils/formatters';

import { HeaderCellGroupProps } from '../../types';
import { StyledTableCell } from '../head';
import styles from './table-secondary.module.css';

const TableSecondaryCellGroup = ({
  headCells,
  secondaryCellGroups,
  cellContentLength,
  maxCellContentLength,
  isLoading,
}: HeaderCellGroupProps) => {
  const mainHeaderCellsCount = secondaryCellGroups
    ? secondaryCellGroups.reduce(
        (count, secondaryCellGroup) => count + secondaryCellGroup.columns,
        0,
      )
    : 0;
  return (
    <TableRow className={styles.tableRow}>
      {secondaryCellGroups.map((secondaryCellGroup, index) => (
        <StyledTableCell
          key={index}
          align={secondaryCellGroup?.align ? secondaryCellGroup.align : 'center'}
          colSpan={secondaryCellGroup?.columns}
          className={
            !isLoading && index === 0
              ? styles.sticky
              : secondaryCellGroup?.border
              ? styles.tableSecondaryCellGroupsWithBorder
              : styles.tableSecondaryCellGroups
          }
          sx={!isLoading && { borderRight: 1, borderColor: 'white', position: 'sticky', top: 60 }}
        >
          <Text variant="body1" color={'white'} fontWeight={'800'}>
            {wrapName(secondaryCellGroup?.headerName, cellContentLength, maxCellContentLength)}
          </Text>
        </StyledTableCell>
      ))}
      {mainHeaderCellsCount < headCells.length + 2 && (
        <StyledTableCell
          colSpan={headCells.length - mainHeaderCellsCount + 2}
          sx={!isLoading && { position: 'sticky', top: 60, zIndex: '100' }}
        />
      )}
    </TableRow>
  );
};
export default TableSecondaryCellGroup;
